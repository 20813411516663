import * as React from 'react'
import { useRef, useState } from 'react'
import { cn } from '~/utils/misc.tsx'
import { inputStandardClassName } from './input.tsx'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'filled' | 'outlined' | 'outlined-label'
  leftIcon?: React.ReactNode
}

const TextareaOutlinedLabel = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(
  (
    {
      className,
      id,
      value,
      placeholder,
      leftIcon,
      variant = 'filled',
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLTextAreaElement | null>(null)
    const [isFocused, setIsFocused] = useState(false)

    const labelShowPlaceholderStyles = `
        peer-placeholder-shown:top-2.5 peer-placeholder-shown:translate-y-0  peer-placeholder-shown:scale-100
        peer-placeholder-shown:text-foreground-muted peer-placeholder-shown:font-normal     `
    const labelShowLabelStyles = `
        left-5 top-0 -translate-y-2 scale-75 duration-200 origin-top-left text-foreground-muted font-semibold peer-focus:font-semibold
        peer-focus:left-5 peer-focus:top-0 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:text-foreground
`

    const handleFocus = (event: any) => {
      props.onFocus && props.onFocus(event)
      setIsFocused(true)
    }
    const handleBlur = (event: any) => {
      props.onBlur && props.onBlur(event)
      setIsFocused(false)
    }
    return (
      <fieldset
        className={cn(
          `relative min-h-[80px] w-full cursor-text rounded-lg border border-input px-4 hover:border-foreground focus:border-2 ${
            isFocused && 'border-2 border-foreground'
          }`,
          props.readOnly && '!cursor-auto !border !border-input',
          className,
        )}
      >
        <textarea
          ref={el => {
            // Assign the input ref to the provided ref
            if (ref) {
              if (typeof ref === 'function') {
                ref(el)
              } else if (ref.hasOwnProperty('current')) {
                // @ts-ignore
                ref.current = el
              }
            }
            inputRef.current = el
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          id={id ?? placeholder}
          aria-describedby="filled_success_help"
          className={cn(
            inputStandardClassName,
            `peer -ml-4 block h-full min-h-[80px] w-[calc(100%+32px)] appearance-none !rounded-lg
          border-none
           bg-white py-3
           pl-5 text-sm   text-foreground focus:ring-0
        `,
            leftIcon && 'pl-10',
          )}
          placeholder=" "
          name={`input-${placeholder}`}
          value={value}
          {...props}
        />
        <legend
          className={`invisible h-[5px] px-1 text-xs  peer-placeholder-shown:hidden peer-focus:block`}
        >
          {placeholder}
        </legend>
        {leftIcon && (
          <div
            className="absolute left-4 top-0 flex h-full cursor-text items-center text-foreground-muted"
            onClick={() => inputRef.current!.focus()}
          >
            {leftIcon}
          </div>
        )}
        <label
          htmlFor={id ?? placeholder}
          className={cn(
            'absolute cursor-text font-normal',
            labelShowLabelStyles,
            labelShowPlaceholderStyles,
            leftIcon && 'peer-placeholder-shown:pl-5 peer-focus:pl-0',
            props.readOnly && '!text-foreground-muted',
          )}
        >
          {placeholder}
        </label>
      </fieldset>
    )
  },
)

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant = 'filled', ...props }, ref) => {
    return variant === 'outlined-label' ? (
      <TextareaOutlinedLabel {...props} />
    ) : (
      <textarea
        className={cn(
          'b hover:border-secondary flex min-h-[80px] w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background placeholder:text-foreground-muted focus-visible:border-2 focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid',
          variant === 'filled' ? 'bg-background' : 'bg-white',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
